@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: fixed;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 32px;
}

::selection {
    background: #2563eb;
    color: white;
}